import _ from 'lodash';

export interface IUrlParam {
  token: string,
  personId: string,
  isReport: string
}

const getUrlParam = (): IUrlParam => {
  const { search } = window.location;
  let obj: Record<string, any> = {};
  if (search) {
    const url = search.slice(1);
    let strs = url.split("&");
    for (let i = 0; i < strs.length; i++) {
      obj[strs[i].split("=")[0]] = strs[i].split("=")[1];
    }
  }
  return obj as IUrlParam;
};

// 世界转图形坐标
export const worldToMap = (x: number, y: number, mapInfo: any) => {
  // static_cast：转成整数， x是获取的世界坐标
  const resolution = _.get(mapInfo, 'resolution', 0);
  const _x = Math.floor((x - _.get(mapInfo, 'originX', 0)) / resolution);
  const _y = Math.floor((y - _.get(mapInfo, 'originY', 0)) / resolution);
  return [_x, _y];
};

// 图形转世界坐标
export const mapToWorld = (x: number, y: number, mapInfo: any) => {
  const resolution = _.get(mapInfo, 'resolution', 0);
  const _x = _.get(mapInfo, 'originX', 0) + (Math.floor(x) + 0.5) * resolution;
  const _y = _.get(mapInfo, 'originY', 0) + (Math.floor(y) + 0.5) * resolution;
  return [_x, _y];
};

export { getUrlParam };
