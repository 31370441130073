import { Feature } from "ol";
import { Layer as LayerLayer, Vector as VectorLayer } from "ol/layer";
import { Vector as VectorSource } from "ol/source";
import { Style, Stroke, Icon, Text } from "ol/style";
import { Point } from "ol/geom";

// 保洁人员图层
export default class user {
  layer: LayerLayer;
  personName: string;

  constructor(extent: number[], personName: string) {
    this.personName = personName;
    const source = new VectorSource({
      wrapX: false,
    });
    this.layer = new VectorLayer({
      source: source,
      style: new Style({
        stroke: new Stroke({
          color: "black",
          width: 2,
        }),
      }),
      properties: { name: "user" },
      extent: extent, // 隐藏其他部分
    });
  }
  removeFeature = () => {
    const source = this.layer.getSource() as VectorSource;
    
    source.clear();
  };
  addFeature = (points: number[]) => {
    const source = this.layer.getSource() as VectorSource;
    // const features = _.get(value, ['geoJson', 'features'], [])
    const features: number[][] = [points];
    this.dataDrawShape(features, source);
  };
  dataDrawShape = (features: any[], source: VectorSource) => {
    features.forEach((data) => {
      const feature = new Feature({
        geometry: new Point(data),
      });
      const style = new Style({
        image: new Icon({
          src: require("../../../assets/user.jpg"),
          // size: [130, 130],
          // imgSize: [130, 130],
          scale: 0.3,
        }),
        text: new Text({
          text: `\n用户: ${this.personName}`,
          textBaseline: "top",
        }),
      });
      feature.setStyle(style);
      source.addFeature(feature);
    });
  };
}
