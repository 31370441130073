import { getUrlParam } from "@/common";
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import _ from "lodash";

enum EEnv {
  development = "development",
  production = "production",
  staging = "staging",
  release = "release",
}

class Api {
  private static instance: Api;
  request: AxiosInstance;
  constructor() {
    // todo baseURL环境区分，小程序根据不同环境跳转不同h5地址，h5本身识别不了环境
    console.log("res===1", process.env.REACT_APP_HOST_TYPE);
    let baseURL =
      process.env.REACT_APP_HOST_TYPE === EEnv.production
        ? "https://api-gateway.staging.gs-robot.com"
        : "https://api-gateway.staging.gs-robot.com";
    // const { pathname } = window.location;
    // if (
    //   pathname.search(EEnv.development) !== -1 ||
    //   pathname.search("dev") !== -1
    // ) {
    //   baseURL = "https://api-gateway.dev.gs-robot.com";
    // } else if (pathname.search(EEnv.staging) !== -1) {
    //   baseURL = "https://api-gateway.staging.gs-robot.com";
    // } else if (pathname.search(EEnv.release) !== -1) {
    //   baseURL = "https://api-gateway.release.gs-robot.com";
    // } else {
    //   //
    // }
    const baseConfig = {
      baseURL: baseURL,
      timeout: 5000,
    };
    this.request = axios.create(baseConfig);
    this.initInterceptor();
  }
  static getInstance() {
    if (!Api.instance) {
      Api.instance = new Api();
    }
    return Api.instance;
  }
  initInterceptor = () => {
    this.request.interceptors.request.use((config: any) => {
      const param = getUrlParam();
      const headers = {
        Authorization: decodeURIComponent(param.token) || "",
      };
      config.headers = { ...config.headers, ...headers };
      console.log("res===interceptors.request.use", config);
      return config;
    });
    this.request.interceptors.response.use(
      (response) => {
        console.log("res===axios.interceptors.response response", response);
        if (response.status === 200) {
          const errcode = _.get(response, ["data", "errcode"], "");
          if (errcode) {
            return Promise.reject(response);
          }
          return Promise.resolve(response);
        }
        return Promise.reject(response);
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  };
  getRequest = async <T>(url: string): Promise<T> => {
    const response = await this.request.get<T, AxiosResponse<T>>(url);
    return response.data;
  };
  /**
   * T: response接口，D:request接口
   * @param url
   * @param params
   * @returns
   */
  postRequest = async <T, D>(url: string, params: D): Promise<T> => {
    const response = await this.request.post<T, AxiosResponse<T>, D>(
      url,
      params
    );
    return response.data;
  };
}

export default Api.getInstance();
