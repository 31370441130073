import { useEffect } from 'react';
import styles from './App.module.less';
import Map from './component/map/Map'
import Home from './component/home/Home';
import {
	Routes,
	Route,
} from "react-router-dom";

function App() {

	return (
		<div className={styles.App}>
			<Routes>
				<Route path='/' element={<Map />}></Route>
				<Route path='/home' element={<Home />}></Route>
			</Routes >
		</div>
	);
}

export default App;
