import React, { Component } from "react";
import { Layer as LayerLayer, Vector as VectorLayer } from "ol/layer";
import { Style, Stroke, Icon, Text } from "ol/style";
import { Vector as VectorSource } from "ol/source";
import { Circle, LineString } from 'ol/geom';
import GeoJSON from 'ol/format/GeoJSON';
import { Feature } from 'ol';
import _ from 'lodash';
import { DrawTypeCloud } from "./base";
import { worldToMap } from "@/common";

export default class fence {
  layer: LayerLayer;

  constructor(extent: number[]) {
    const source = new VectorSource({
      wrapX: false,
    });
    this.layer = new VectorLayer({
      source: source,
      style: new Style({
        stroke: new Stroke({
          color: "blue",
          width: 2,
        }),
      }),
      properties: { name: "fence" },
      extent: extent, // 隐藏其他部分
    });
  }

  dataDrawShapeFence = (featuresJson: any[], mapInfo: any) => {
    const source = this.layer.getSource() as VectorSource;
    featuresJson.forEach((data) => {
      const properties = _.get(data, ['properties'], {});
      const type = _.get(properties, ['type'], '');
      const name = _.get(properties, ['name'], '');
      const styles = new Style({
        stroke: new Stroke({
          color: 'blue',
          width: 2,
        }),
        text: new Text({
          text: `${name}`,
          textBaseline: "top",
        }),
      });
      // const style = new Style({
      //   image: new Icon({
      //     src: require("../../../assets/user.jpg"),
      //     scale: 0.3,
      //   }),
      //   text: new Text({
      //     text: `\n用户: ${this.personName}`,
      //     textBaseline: "top",
      //   }),
      // });
      // feature.setStyle(style);
      if (type === DrawTypeCloud.circles) {
        const x = _.get(properties, ['center', 'x'], 0);
        const y = _.get(properties, ['center', 'y'], 0);
        const point = worldToMap(x, y, mapInfo);
        const radius = _.get(properties, ['radius'], 0);
        // 还是使用半径和中心点，所以不用做世界坐标转换
        const circle = new Circle(point, radius);
        const feature = new Feature();
        feature.setProperties({ ...properties, ...{ geometry: circle } });
        feature.setStyle(styles);
        source.addFeature(feature);
      } else if (type === DrawTypeCloud.polygons) {
        const properties = _.get(data, ['properties'], {});
        const coordinates = _.get(data, ['geometry', 'coordinates'], []);
        if (coordinates.length <= 0) return;
        const coords = (coordinates[0] as number[][]).map((item) => {
          const point = worldToMap(item[0], item[1], mapInfo);
          return point;
        });
        const featureData = _.cloneDeep(data);
        _.set(featureData, ['geometry', 'coordinates'], [coords]);
        const feature = new GeoJSON().readFeature(featureData);
        feature.setProperties(properties);
        feature.setStyle(styles);
        source.addFeature(feature);
      } else {
        // 无
      }
    });
  };
}
