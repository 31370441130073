import ol, { Map, View } from "ol";
import Projection from "ol/proj/Projection";
import { getCenter } from "ol/extent";
import Static from "ol/source/ImageStatic";
import { Image as ImageLayer } from "ol/layer";
import _ from "lodash";

export default class map {
  map: Map;
  extent: number[];
  bgLayer: ImageLayer<Static>;
  bgSource: Static;

  constructor(info: any) {
    const { mapMetaData, downloadUri = "" } = info;
    const gridWidth = _.get(mapMetaData, ["mapInfo", "gridWidth"], 0);
    const gridHeight = _.get(mapMetaData, ["mapInfo", "gridHeight"], 0);
    this.extent = [0, 0, gridWidth, gridHeight];
    const projection = new Projection({
      code: "EPSG:4326",
      units: "pixels",
      extent: this.extent,
    });
    this.bgSource = new Static({
      // url: "https://xiuxiupro-material-center.meitudata.com/poster/25875245f8a5e600575bf66cc63eb166.png",
      url: downloadUri,
      imageExtent: this.extent,
    });
    this.bgLayer = new ImageLayer({
      source: this.bgSource,
    });
    this.map = new Map({
      layers: [
        // 背景层
        this.bgLayer,
      ],
      view: new View({
        projection: projection,
        center: getCenter(this.extent),
        extent: this.extent,
        zoom: 0,
        maxZoom: 3,
        showFullExtent: true,
      }),
      controls: [],
      target: "openlayerMap",
    });
    const width = this.map.getSize()![0];
    const height = width / (gridWidth / gridHeight);
    this.map.setSize([width, height]);
    this.map.getView().setViewportSize([width, height]);
    const currentMapDiv = document.querySelector("#openlayerMap");
    currentMapDiv && currentMapDiv.setAttribute("style", `height: ${height}px`);
  }

  unmounted() {
    console.log("res===unmounted");
  }
}
